import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mpk-user-blocked-icon',
  standalone: true,
  templateUrl: './user-blocked-icon.component.html',
  styleUrl: './user-blocked-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class UserBlockedIconComponent {}
